import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import './NavSliderContent.scss'

import CaretTriangleIcon from '../../assets/svg/caret-triangle-red.svg'

type NavType = {
  name: string
  url?: string
}

type NavSliderContentType = {
  navItem: NavType
  subItems: NavType[]
  isOpened: boolean
  setOpened(isOpened: boolean): void
  className?: string
}

const NavSliderContent = ({
  navItem,
  setOpened,
  isOpened,
  subItems,
  className,
}: NavSliderContentType): ReactElement => {
  return (
    <div className={`nav-slider-content${className ? ` ${className}` : ''}`}>
      <div
        className={`nav-slider-content-container ${isOpened ? 'active' : ''}`}
        onClick={(): void => setOpened(!isOpened)}
        onKeyDown={(): void => setOpened(!isOpened)}
        role="button"
        aria-label="nav-slider-content-container"
        tabIndex={0}
      >
        <p className="nav-slider-content-container-dropdown">{navItem.name}</p>
        <img src={CaretTriangleIcon} alt="caret triangle" />
      </div>

      {isOpened && navItem.name === 'Properties' ? (
        <ul className="nav-slider-content-hide">
          {subItems.map(({ name, url }) => {
            const searchTypeMap: Record<
              string,
              { value: string; label: string }
            > = {
              Condominium: {
                value: 'Condominium',
                label: 'Condominium',
              },
              'House & Lot': {
                value: 'House and Lot',
                label: 'House & Lot',
              },
              Office: { value: 'Office', label: 'Office' },
            }

            const searchType = searchTypeMap[name] || {
              value: '',
              label: '',
            }
            return (
              <li key={name}>
                <Link
                  key={name}
                  to={`${url}`}
                  state={{
                    searchType,
                  }}
                  className="nav-slider-content-hide-link"
                >
                  {name.toUpperCase()}
                </Link>
              </li>
            )
          })}
        </ul>
      ) : null}

      {isOpened && navItem.name === 'News & Blogs' ? (
        <ul className="nav-slider-content-hide">
          {subItems.map(({ name, url }) => {
            const searchTypeMap: Record<
              string,
              { value: string; label: string }
            > = {
              News: {
                value: 'news',
                label: 'News',
              },
              Events: {
                value: 'events',
                label: 'Events',
              },
              POP: {
                value: 'pursuit-of-passion',
                label: 'Pursuit of Passion',
              },
              'Awards & Recognition': {
                value: 'awards',
                label: 'Awards & Recognition',
              },
            }

            const searchType = searchTypeMap[name] || {
              value: '',
              label: '',
            }
            return (
              <li key={name}>
                <Link
                  key={name}
                  to={`${url}`}
                  state={{
                    searchType,
                  }}
                  className="nav-slider-content-hide-link"
                >
                  {name.toUpperCase()}
                </Link>
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  )
}

export default NavSliderContent
