import React, { ReactElement } from 'react'
import Select, { ValueType } from 'react-select'
import './PropertySearchSelect.scss'

export type OptionTypes = {
  value: string
  label: string
}

type SearchSelectPropTypes = {
  options: OptionTypes[]
  placeholder?: string
  // eslint-disable-next-line
  className?: string
  defaultValue?: OptionTypes | null
  onChange?: (data: ValueType<OptionTypes>) => void
}

const PropertySearchSelect = ({
  options,
  placeholder,
  defaultValue,
  onChange,
}: SearchSelectPropTypes): ReactElement => {
  return (
    <div className="property-search-select-container">
      <Select
        className="property-search-select"
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        isSearchable
        defaultValue={defaultValue || null}
      />
    </div>
  )
}

export default PropertySearchSelect
