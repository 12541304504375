import React, { ReactElement } from 'react'
import './SideModalDefault.scss'
import Helmet from 'react-helmet'
import closeIcon from '../../assets/svg/close.svg'

type SideModalPropTypes = {
  onClose(): void
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
}

const SideModalDefault = ({
  onClose,
  children,
}: SideModalPropTypes): ReactElement => {
  return (
    <>
      <div className="modal-background" />
      <Helmet>
        <body className="modal-open" />
      </Helmet>
      <div className="side-modal" data-testid="side-modal">
        <button type="button" onClick={onClose} className="side-modal-close">
          <img src={closeIcon} alt="close icon" />
        </button>
        {children}
      </div>
    </>
  )
}

export default SideModalDefault
