import React, { useState, useEffect, ReactElement } from 'react'
import { Link } from 'gatsby'

type RouteLinkTypes = {
  to: string
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
  rel?: string
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

const RouteLink = ({
  to,
  children,
  rel,
  className,
  onClick,
}: RouteLinkTypes): ReactElement => {
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  return (
    <Link
      to={to}
      state={{ prevUrl: currentUrl }}
      rel={rel}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
export default RouteLink
