import React, { ReactElement, useCallback, useState } from 'react'
import './PropertySearchInput.scss'
import SearchIcon from '../../assets/svg/search-icon.svg'

type PropertySearchInputPropTypes = {
  placeholder?: string
  defaultValue?: string
  setSearchTerm?: (value: string) => void
}

const PropertySearchInput = ({
  placeholder,
  defaultValue = '',
  setSearchTerm,
}: PropertySearchInputPropTypes): ReactElement => {
  const [value, setValue] = useState<string>(defaultValue)

  const handleOnChange = useCallback(
    (e) => {
      const currValue = e.target.value

      setValue(currValue)
      if (setSearchTerm) {
        setSearchTerm(currValue)
      }
    },
    [setSearchTerm],
  )

  return (
    <div className="property-search-input-container">
      <img src={SearchIcon} className="property-search-input-icon" alt="search icon" />
      <input
        type="text"
        placeholder={placeholder}
        className="property-search-input"
        onChange={handleOnChange}
        value={value}
      />
    </div>
  )
}

export default PropertySearchInput
