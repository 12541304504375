import React, { ReactElement, useEffect, useState } from 'react'
import './HeaderMenu.scss'
import { graphql, Link, useStaticQuery } from 'gatsby'
import RouteLink from '../RouteLink/RouteLink'
import headerMenuNav from '../../data/header-menu-nav.json'
import NavSliderContent from '../NavSliderContent/NavSliderContent'

type LinkTypes = {
  url: string
}

type HeaderMenuQueryTypes = {
  japaneseSite?: LinkTypes
  leasing?: LinkTypes
  otherBrandLinks?: {
    nodes: {
      name: string
      url: string
    }[]
  }
}

const HeaderMenu = ({
  showMenu,
  onShow,
}: {
  showMenu: boolean
  onShow(showMenu: boolean): void
}): ReactElement => {
  const { japaneseSite, leasing, otherBrandLinks }: HeaderMenuQueryTypes =
    useStaticQuery(graphql`
      query HeaderMenuQuery {
        japaneseSite: link(
          category: { eq: "external" }
          name: { eq: "japanese_site" }
        ) {
          ...LinkFields
        }
        leasing: link(category: { eq: "external" }, name: { eq: "leasing" }) {
          ...LinkFields
        }
        otherBrandLinks: allLink(
          filter: {
            category: { eq: "external" }
            name: {
              in: [
                "ayala_land"
                "ayala_land_premiere"
                "alveo"
                "amaia"
                "bellavita"
                "book_an_office_appointment"
              ]
            }
          }
        ) {
          nodes {
            name
            url: link
          }
        }
      }
    `) || {}
  const [isOpenedBrand, setOpenedBrand] = useState(false)
  const [isOpenedLanguage, setOpenedLanguage] = useState(false)
  const [navItems, setNavItems] = useState(headerMenuNav)
  const [brandLinks] = useState(
    otherBrandLinks?.nodes.map((brandLink) => ({
      name: brandLink.name.replace(/_/g, ' '),
      url: brandLink.url,
    })) || [],
  )

  const subItems1 = [
    {
      name: 'Japanese',
      url: japaneseSite?.url || '',
    },
  ]

  useEffect(() => {
    if (leasing?.url) {
      const updatedNavItems = navItems.map((item) =>
        item.name === 'Leasing' ? { ...item, url: leasing.url } : item,
      )

      setNavItems(updatedNavItems)
    }

    const book_an_office_appointment = brandLinks.filter(
      (brand) => brand.name.toLowerCase() === 'book an office appointment',
    )[0]

    const withBookAppointmentUrl = navItems.map((navItem) => {
      if (navItem.name.toLowerCase() === book_an_office_appointment.name) {
        navItem.url = book_an_office_appointment.url
      }
      return navItem
    })

    setNavItems(withBookAppointmentUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasing])

  return (
    <>
      <div className="menu-container">
        <div className="menu-content">
          <ul className="menu-content-list" data-testid="">
            {navItems.map((navItem) => {
              if (navItem.name.match(/Ayala/gi)) {
                return brandLinks.length ? (
                  <div key={navItem.name} className="menu-content-list-section">
                    <NavSliderContent
                      navItem={navItem}
                      setOpened={setOpenedBrand}
                      isOpened={isOpenedBrand}
                      subItems={brandLinks}
                    />
                  </div>
                ) : (
                  <></>
                )
              }
              if (navItem.name.match(/English/gi)) {
                return japaneseSite ? (
                  <div key={navItem.name} className="nav-content-list-section">
                    <NavSliderContent
                      navItem={navItem}
                      setOpened={setOpenedLanguage}
                      isOpened={isOpenedLanguage}
                      subItems={subItems1}
                    />
                  </div>
                ) : (
                  <></>
                )
              }
              const newTab = navItem.name.includes('Book') ? '_blank' : ''
              return (
                <li key={navItem.name}>
                  <Link
                    to={`${navItem.url}`}
                    activeClassName="active"
                    partiallyActive
                    className="test"
                    target={newTab}
                  >
                    {navItem.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
export default HeaderMenu
